::selection {
  background: var(--bs-success);
  color: var(--bs-light);
}

::-moz-selection {
  background: var(--bs-success);
  color: var(--bs-light);
}

::-webkit-scrollbar {
  display: none;
}

img {
  pointer-events: none;
  user-select: none;
}

@font-face {
  font-family: 'infrared';
  src: url('fonts/InfraRed.woff'),
  url('fonts/InfraRed.woff2');
  font-weight: normal;
  font-style: normal;
}
.InfraRed {
  font-family: 'infrared', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f212b !important;
  color: antiquewhite !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.box {
  background-color: #2e303a !important;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.box h2 {
  color: var(--bs-success);
}
.logo {
  max-height: 280px;
}

green {
  color: #d16b1e
}
